// components/Body.js
import React from "react";
import { Button, Container, Typography, Box, Grid, TextField } from "@mui/material";
import Laptop from "../../assets/Laptop.png"; // Import your background image here
import Bottom from "../../assets/Bottom.png"; // Import your background image here

const Footer = () => {
  const placeholderImages = [
    {
      image:
        "https://firebasestorage.googleapis.com/v0/b/webcommerce-f85da.appspot.com/o/icon%20(1).png?alt=media&token=332a4aec-8386-4c94-b493-93ec1e6f46a1", // Placeholder image URL
      text: "High Quality",
      subText: "crafted from top materials",
    },
    {
      image:
        "https://firebasestorage.googleapis.com/v0/b/webcommerce-f85da.appspot.com/o/icon.png?alt=media&token=72adc7ca-9072-4066-9c67-dfc021dccf8c", // Placeholder image URL
      text: "Warrany Protection",
      subText: "Life time",
    },
    {
      image:
        "https://firebasestorage.googleapis.com/v0/b/webcommerce-f85da.appspot.com/o/Vector%402x.png?alt=media&token=48a2986f-b9d7-4ae2-abb3-728b046c40c9", // Placeholder image URL
      text: "Free Shipping",
      subText: "Order over 150 $",
    },
    {
      image:
        "https://firebasestorage.googleapis.com/v0/b/webcommerce-f85da.appspot.com/o/2891214031638194523%201%402x.png?alt=media&token=d3f6eac7-f2ca-4969-9bd2-d602d4a0819e", // Placeholder image URL
      text: "Need Help?",
      subText: "Dedicated support",
    },
  ];
  return (
    <>
      <div
        style={{
          // marginTop: 4,
          backgroundColor: "#002016",
          width: "100%",
          // position: 'relative' // Set position to relative for absolute positioning of inner Box

          padding: 0,
          // overflow: 'hidden'   // Ensures that any overflow content is hidden
        }}
        maxWidth={false}
      >
        
        

        <Box
          sx={{
            backgroundColor: "#013927", // Background color for the container
            width: "100%",
            py: 4, // Padding top and bottom
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.8)", // Shadow for the container
          }}
        >
          <Container sx={{ maxWidth: "lg" }}>
            <Grid container spacing={2}>
              {placeholderImages.map((item, index) => (
                <Grid item xs={12} sm={6} lg={3} key={index}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: "transparent",
                      borderRadius: "8px",
                      // boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.4)',
                      p: 2,
                      height: "auto",
                    }}
                  >
                    <Box
                      sx={{
                        flexShrink: 0,
                        mr: 2,
                        width: "40px", // Fixed width for images
                        height: "50px",
                      }}
                    >
                      <img
                        src={item.image}
                        alt={`Placeholder ${index + 1}`}
                        style={{
                          width: "50px",
                          height: "50px",
                          objectFit: "contain",
                          borderRadius: "8px",
                        }}
                      />
                    </Box>
                    <Box sx={{ flexGrow: 1 }}>
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: "bold",
                          mb: 1,
                          fontSize: "20px",
                          color: "#fff",
                        }}
                      >
                        {item.text}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          color: "#fff",
                          fontSize: "16px",
                        }}
                      >
                        {item.subText}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Container>
        </Box>
        <Box
          sx={{
            backgroundColor: "#002016", // Background color for the box
            width: "100%",
            py: 4,
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.8)", // Shadow for the box
          }}
        >
          <Container sx={{ maxWidth: "lg" }}>
            <Grid container spacing={10}>
              {/* Left Column */}
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    // justifyContent: 'center',
                    height: "100%",
                    textAlign: "left",

                    color: "#fff",
                  }}
                >
                  <Typography
                    variant="h4"
                    sx={{
                      fontSize: {
                        xs: "24px", // Font size for extra small screens
                        sm: "24px", // Font size for small screens (tablets)
                        md: "32px", // Font size for medium screens
                        lg: "46px", // Font size for large screens
                      },
                      fontWeight: "bold",
                      mb: 1,
                      textAlign: "left", // Ensure text is aligned left on all screens
                    }}
                  >
                    NEVER MISS A THING
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: {
                        xs: "14px", // Font size for extra small screens
                        sm: "16px", // Font size for small screens (tablets)
                        md: "16px", // Font size for medium screens
                        lg: "16px", // Font size for large screens
                      },
                      textAlign: "left", // Ensure text is aligned left on all screens
                      color: "#BFBFBF",
                    }}
                  >
                    Sign up for promotions, tailored new arrivals, stock updates
                    and more - straight to your inbox
                  </Typography>
                </Box>
              </Grid>
              {/* Right Column */}
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    display: "flex",

                    flexDirection: "column",
                    // justifyContent: 'center',
                    height: "100%",
                    textAlign: "left",
                    color: "#fff",
                  }}
                >
                  <Typography
                    variant="h4"
                    sx={{
                      fontSize: {
                        xs: "24px", // Font size for extra small screens
                        sm: "24px", // Font size for small screens (tablets)
                        md: "32px", // Font size for medium screens
                        lg: "46px",
                      },
                      fontWeight: "bold",
                      mb: 1,
                      textAlign: "left", // Ensure text is aligned left on all screens
                    }}
                  >
                    Email Me
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: {
                        xs: "14px", // Font size for extra small screens
                        sm: "16px", // Font size for small screens (tablets)
                        md: "16px", // Font size for medium screens
                        lg: "16px", // Font size for large screens
                      },
                      textAlign: "left", // Ensure text is aligned left on all screens
                      color: "#BFBFBF",
                    }}
                  >
                    Enter your email and we'll send you a link to download the
                    free app
                  </Typography>

                  <TextField
                    variant="outlined"
                    placeholder="Enter the email"
                    sx={{
                      height: "67px",
                      // mt: 2,
                      borderRadius: 4,
                      marginTop: "20px",

                      flex: 1,
                      borderWidth: 0,
                      borderColor: "#002016",
                      backgroundColor: "#fff",
                      marginRight: "10px", // Optional margin to separate from the right side
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderRadius: "4px",
                        },
                        "&:hover fieldset": {
                          borderRadius: "4px",
                        },
                        "&.Mui-focused fieldset": {
                          borderRadius: "4px",
                        },
                      },
                    }}
                  />
                            <Button
            variant="contained"
            sx={{
              marginTop: "20px",

              width: {
                xs: '50px',
                sm: '50px',
                md: '207px',
                lg: "207px"

              },
              height: '90%',
              background: 'linear-gradient(to right, #00563A, #00AC76)',  // Gradient background
              color: '#fff',
              fontSize: {
                xs: '13px',
                sm: '13px',
                md: '19px',
                lg: "19px"

              },

              borderRadius: '10px',
              textTransform: 'none',  // Keeps the text as "Search" instead of "SEARCH"
            }}
          >
            Send
          </Button>

                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </div>
    </>
  );
};

export default Footer;
