// components/Header.js
import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, IconButton, Box, Drawer, List, ListItem, ListItemText } from '@mui/material';
import { FavoriteBorder as FavoriteIcon, ShoppingCart as ShoppingCartIcon, Menu as MenuIcon, Person as PersonIcon } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import Logo from '../../assets/Logo.png'; // Import your logo image here

const Header = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const menuItems = [
    { text: 'Women', link: '/women' },
    { text: 'Men', link: '/men' },
    { text: 'Kids', link: '/kids' },
    { text: 'Home', link: '/home' },
    { text: 'New in', link: '/new-in' },
    { text: 'Brands', link: '/brands' },
    { text: 'Clothing', link: '/clothing' },
    { text: 'Shoes', link: '/shoes' },
    { text: 'Bags', link: '/bags' },
    { text: 'Accessories', link: '/accessories' },
    { text: 'Jewelry', link: '/jewelry' },
    { text: 'Homeware', link: '/homeware' },
    { text: 'Pre-owned', link: '/pre-owned' },
    { text: 'Sale', link: '/sale' },
  ];

  return (
    <AppBar position="static" sx={{ width: '100%', backgroundColor: '#CAE6D5', padding: { xs: '0 1rem', sm: '0 2rem' } }}>
        {/* Logo */}
        <Box sx={{ flexGrow: 1, textAlign: 'center', display: { xs: 'none', sm: 'block' } }}>
          <Link to="/">
            <img src={Logo} alt="My Logo" style={{ marginTop:"10px", maxHeight: 60 , marginBottom:"-20px"}} />
          </Link>
        </Box>
      <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom:"10px",   }}>

        {/* Left Side Menu (for larger screens) */}
        <Box sx={{ display: { xs: 'none', sm: 'flex' }, flexDirection: 'column', alignItems: 'flex-start', width: '70%' }}>
          {/* Upper Menu */}
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: 2,
              mb: 1,
              typography: { xs: 'body2', md: 'body1' },
            }}
          >
            <Typography><Link to="/women" style={{ color: '#003926', fontWeight:"bold", textDecoration: 'none' }}>Women</Link></Typography>
            <Typography><Link to="/men" style={{ color: '#003926', fontWeight:"bold", textDecoration: 'none' }}>Men</Link></Typography>
            <Typography><Link to="/kids" style={{ color: '#003926', fontWeight:"bold", textDecoration: 'none' }}>Kids</Link></Typography>
          </Box>

          {/* Lower Menu */}
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: 2,
              typography: { xs: 'caption', md: 'body2' },
            }}
          >
            {menuItems.slice(4).map((item) => (
              <Typography   sx={{
                color:"#003926"
              }}  key={item.text}>
                <Link to={item.link} style={{ color: '#003926', textDecoration: 'none' }}>{item.text}</Link>
              </Typography>
            ))}
          </Box>
        </Box>

    

        {/* Hamburger Menu Icon (for mobile) */}
        <IconButton color="inherit" sx={{ display: { xs: 'flex', sm: 'none' } , color: '#003926'  }} onClick={toggleDrawer(true)}>
          <MenuIcon />
        </IconButton>
            {/* left Side Icons */}
            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
          <IconButton color="inherit" sx={{ color: '#003926' }} >
            <FavoriteIcon />
          </IconButton>
          <IconButton color="inherit" sx={{ color: '#003926' }} >
            <ShoppingCartIcon />
          </IconButton>
          <Link to="/account" style={{ textDecoration: 'none', color: 'inherit' }}>
        <IconButton color="inherit" sx={{ color: '#003926' }}>
          <PersonIcon />
        </IconButton>
      </Link>
        </Box>


      </Toolbar>

      {/* Drawer for mobile menu */}
      <Drawer   anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
        <Box
          sx={{ width: 250, backgroundColor: '#CAE6D5' }}
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
           <Box sx={{ padding: 2, textAlign: 'center' }}>
            <Link to="/">
              <img src={Logo} alt="My Logo" style={{ maxHeight: 60 }} />
            </Link>
          </Box>
          <List>
            {menuItems.map((item) => (
              <ListItem button key={item.text} component={Link} to={item.link}>
                <ListItemText primary={item.text}   
                        sx={{ color: '#003926', fontWeight: 'bold' }} 

                />
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>
    </AppBar>
  );
};

export default Header;
