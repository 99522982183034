// components/HeroSection.js
import React from 'react';
import { Container, Typography, Button, Box, TextField } from '@mui/material';
import backgroundImage from '../../assets/backgroundImage.png'; // Import your background image here
import Tablet from '../../assets/Tablet.png'; // Import your background image here
import { Link } from 'react-router-dom';

const HeroSection = () => {
  return (
    <Box
      sx={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        justifyContent: "center",
        // alignItems:"center",
        backgroundRepeat: 'no-repeat',
        // textAlign: 'center',
        color: 'white', // Adjust text color for better contrast
        height: {
          xs: '400px',  // Height for extra small screens
          sm: '500px',  // Height for small screens (tablets)
          md: '600px',  // Height for medium screens
          lg: '605px',  // Height for large screens (desktops)
        },
        padding: {
          xs: '2rem 1rem', // Padding for extra small screens
          sm: '3rem 1rem', // Padding for small screens
          md: '4rem 2rem', // Padding for medium screens
          lg: '4rem 2rem', // Padding for large screens
        },
      }}
    >
      <Container>
        <Typography variant="h2"
          sx={
            {
              fontSize: {
                xs: '24px',  // Height for extra small screens
                sm: '24px',  // Height for small screens (tablets)
                md: '35px',  // Height for medium screens
                lg: '60px',  // Height for large screens (desktops)
              },
            }
          }
          component="h1" gutterBottom>
          Exclusive Connections, <br /> Discreet Transaction
        </Typography>
        <Box
          sx={{
            height: {
              xs: '40px',  // Height for extra small screens
              sm: '40px',  // Height for small screens (tablets)
              md: '50px',  // Height for medium screens
              lg: '60px',
            },
            width: {
              xs: '220px',  // Width for extra small screens
              sm: '220px',  // Width for small screens (tablets)
              md: '420px',  // Width for medium screens
              lg: '605px',  // Width for large screens (desktops)
            },
            marginBottom: "20px",
            backgroundColor: "#fff",
            borderRadius: "20px",
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '0 10px',  // Optional padding to add some space inside the box
          }}
        >
          {/* Left side: Text input */}
          <TextField
            variant="outlined"
            placeholder="Enter Product name"
            sx={{
              flex: 1,
              marginRight: '10px',  // Optional margin to separate from the right side
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderWidth: 0,  // Removes the border
                },
                '&:hover fieldset': {
                  borderWidth: 0,  // Removes the border on hover
                },
                '&.Mui-focused fieldset': {
                  borderWidth: 0,  // Removes the border when focused
                },
              },
            }}
          />

          {/* Right side: Button */}
          <Link to="/trending" style={{ textDecoration: 'none', color: 'inherit' }}>


          <Button
            variant="contained"
            sx={{
              width: {
                xs: '50px',
                sm: '50px',
                md: '207px',
                lg: "207px"

              },
              height: '90%',
              background: 'linear-gradient(to right, #00563A, #00AC76)',  // Gradient background
              color: '#fff',
              fontSize: {
                xs: '13px',
                sm: '13px',
                md: '19px',
                lg: "19px"

              },

              borderRadius: '20px',
              textTransform: 'none',  // Keeps the text as "Search" instead of "SEARCH"
            }}
          >
            Search
          </Button>
</Link>
        </Box>
        <div
          style={{

            height: "100px"
          }}
        />
        <Box
          sx={{
            height: {
              xs: '90px',  // Height for extra small screens
              sm: '90px',  // Height for small screens (tablets)
              md: '130px',  // Height for medium screens
              lg: '153px',
            },
            width: {
              xs: '80px',  // Width for extra small screens
              sm: '80px',  // Width for small screens (tablets)
              md: '80%',  // Width for medium screens
              lg: '80%',  // Width for large screens (desktops)
            },
            background: 'linear-gradient(to right, #018056, #04ECA3)',  // Gradient background
            borderRadius: "300px",
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: {
              xs: '0 100px',   // Smaller padding for extra small screens
              sm: '0 100px',   // Padding for small screens
              md: '0 100px',   // Padding for medium screens
              lg: '0 100px',  // Padding for large screens
            },

            // padding: '0 100px',  // Updated padding for left and right
            alignSelf: 'center',  // Aligns the box center horizontally within a flex container
            margin: '0 auto',  // Center horizontally if parent container isn't a flexbox
          }}
        >
          <Box>


            <Typography variant="h2" component="h1" gutterBottom
              sx={
                {

                  fontSize: {
                    xs: '14px',  // Width for extra small screens
                    sm: '14px',  // Width for small screens (tablets)
                    md: '20px',  // Width for medium screens
                    lg: '37px',  // Width for large screens (desktops)
                  },
                  marginLeft: {
                    xs: '-40px',  // Width for extra small screens
                    sm: '-40px',  // Width for small screens (tablets)
                    md: '0px',  // Width for medium screens
                    lg: '0px',


                  }
                }


              }
            >
              Elevating Anonymous
            </Typography>
            <Typography variant="h2" component="h1" gutterBottom
              sx={
                {

                  marginLeft: {
                    xs: '-40px',  // Width for extra small screens
                    sm: '-40px',  // Width for small screens (tablets)
                    md: '0px',  // Width for medium screens
                    lg: '0px',


                  },
                  fontSize: {
                    xs: '14px',  // Width for extra small screens
                    sm: '14px',  // Width for small screens (tablets)
                    md: '20px',  // Width for medium screens
                    lg: '37px',  // Width for large screens (desktops)
                  },
                }


              }
            >
              B2B Luxury commerce
            </Typography>
          </Box>
          <Box
            sx={{

              marginRight: {
                xs: "30px",
                sm: "30px",

              }

            }}
          />


          <img
            src={Tablet}
            alt="Tablet Image"
            style={{
              width: '100%', // Adjust the width to be responsive
              maxWidth: '485.63px', // Set a max width to control scaling on larger screens
              height: 'auto', // Make height auto to maintain aspect ratio
              objectFit: 'cover',  // Ensures the image covers the specified dimensions
              borderRadius: '8px',  // Optional: Add rounded corners if desired
            }}
          />


        </Box>

      </Container>

    </Box>
  );
};

export default HeroSection;
