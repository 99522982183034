import React, { useState } from 'react';
import { Box, Button, Typography, Modal, TextField, IconButton } from '@mui/material';
import InventoryIcon from '@mui/icons-material/Inventory2Outlined';
import AddIcon from '@mui/icons-material/Add';
import ImageIcon from '@mui/icons-material/Image';

function MyInventory() {
    const [open, setOpen] = useState(false);
    const [afteropen, setafteropen] = useState(false)
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false)
        setafteropen(true)
    };

    return afteropen ? (
        <Box sx={{ height: '80vh', display: 'flex', flexDirection: 'column', alignItems:'start', justifyContent: 'start', color: '#FFFFFF' }}>

            <Typography variant="h4" sx={{ fontSize: 30 }}>My Inventory</Typography>
            <Box sx={{ display: 'flex', alignItems: 'start', mb: 4, width: '100%', padding: 2 ,justifyContent:"space-between"}}>
                <Box sx={{ display: 'flex', alignItems: 'center', backgroundColor: '#FFFFFF', borderRadius: 1, width: '300px',height:"40px", padding: 1,borderRadius:3 }}>
                    <TextField
                        label="Search Product"
                        variant="filled"
                        fullWidth
                        sx={{
                            backgroundColor: '#FFFFFF',
                            color: '#000000',
                            '& .MuiInputBase-input': {
                                color: '#000000',
                            },
                            '& .MuiInputLabel-root': {
                                color: '#000000',
                            },
                            '& .MuiFilledInput-root': {
                                backgroundColor: '#FFFFFF',
                                '&:before': {
                                    borderBottom: 'transparent', // Black underline
                                },
                                '&:hover:before': {
                                    borderBottom: 'transparent', // Black underline on hover
                                },
                            },
                            mr: 2
                        }}
                    />

                    <Button
                        sx={{
                            background: 'linear-gradient(90deg, #00563A 0%, #00AC76 100%)',
                            color: '#fff',
                            '&:hover': {
                                background: 'linear-gradient(90deg, #004d2d 0%, #009966 100%)',
                            },
                            textTransform: 'none',
                            padding: 1,
                            borderRadius: 2
                        }}
                    >
                        Search
                    </Button>
                </Box>
                <Button
                    onClick={handleOpen}
                    sx={{
                        background: 'linear-gradient(90deg, #00563A 0%, #00AC76 100%)',
                        color: '#fff',
                        '&:hover': {
                            background: 'linear-gradient(90deg, #004d2d 0%, #009966 100%)',
                        },
                        textTransform: 'none',
                        padding: 1,
                        borderRadius: 2,
                        ml: 2
                    }}
                >
                    Add Product
                </Button>
            </Box>
            <Box
            sx={{
                backgroundColor: '#013927',
                color: '#FFFFFF',
                padding: 3,
                borderRadius: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyItems:"start",
                width: '100%',
                // maxWidth: 800,
                margin: 'auto',
                width:"100%",
            }}
        >
            {/* Product ID and Published Date */}
            <Typography variant="h6" sx={{ mb: 2 }}>
                Product ID: <strong>#3922373945</strong>
            </Typography>
            <Typography variant="body2" sx={{ mb: 2 }}>
                Published on: <strong>14 March 2024 02:03:21</strong>
            </Typography>

            {/* Product Image and Details */}
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    mb: 2,
                    width: '100%',
                    gap: 2
                }}
            >
                {/* Product Image */}
                <Box
                    sx={{
                        // width: 150,
                        // height: 150,
                        backgroundColor: '#FFFFFF',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: 1
                    }}
                >
                    <ImageIcon sx={{ fontSize: 80, color: '#000000' }} /> {/* Replace this with an image */}
                </Box>

                {/* Product Details */}
                <Box sx={{ flex: 1 }}>
                    <Typography variant="body1" sx={{ mb: 1 }}>Prada</Typography>
                    <Typography variant="body2" sx={{ mb: 1 }}>
                        Single-breasted pinstripe wool vest
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 1 }}>
                        Price: $2,377
                    </Typography>
                    <Typography variant="body2">
                        SKU: 10 item(s)
                    </Typography>
                </Box>

                {/* Action Buttons */}
                <Box sx={{ display: 'flex', flexDirection: 'column', ml: 2,padding:2,borderRadius:5 }}>
                    <Button
                        sx={{
                            background: 'linear-gradient(90deg, #00563A 0%, #00AC76 100%)',
                            color: '#fff',
                            mb: 1,
                            textTransform: 'none',
                            '&:hover': {
                                background: 'linear-gradient(90deg, #004d2d 0%, #009966 100%)',
                            }
                        }}
                    >
                        Publish
                    </Button>
                    <Button
                        sx={{
                            background: 'linear-gradient(90deg, #00563A 0%, #00AC76 100%)',
                            color: '#fff',
                            mb: 1,
                            textTransform: 'none',
                            '&:hover': {
                                background: 'linear-gradient(90deg, #004d2d 0%, #009966 100%)',
                            }
                        }}
                    >
                        Edit
                    </Button>
                    <Button
                        sx={{
                            background: 'linear-gradient(90deg, #00563A 0%, #00AC76 100%)',
                            color: '#fff',
                            textTransform: 'none',
                            '&:hover': {
                                background: 'linear-gradient(90deg, #004d2d 0%, #009966 100%)',
                            }
                        }}
                    >
                        Delete
                    </Button>
                </Box>
            </Box>
        </Box>


            {/* Modal for adding product */}
            <Modal open={open} onClose={handleClose} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Box
                    sx={{
                        width: '80%',
                        maxWidth: '800px',
                        bgcolor: '#013927',
                        p: 4,
                        borderRadius: 2,
                        color: '#FFFFFF',
                        overflowY: 'auto',
                    }}
                >
                    <Typography variant="h4" sx={{ mb: 2, fontSize: 30, textAlign: 'center' }}>
                        Add Product
                    </Typography>

                    <Typography variant="h6" sx={{ mb: 1 }}>Add Image</Typography>

                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, gap: 5 }}>
                        <Box sx={{ width: 150, height: 150, backgroundColor: '#FFF', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <ImageIcon sx={{ fontSize: 80, color: '#000000' }} />
                        </Box>
                        {/* <Box sx={{ width: 150, height: 150, backgroundColor: '#FFF', display: 'flex', alignItems: 'center', justifyContent: 'center' }}> */}

                        <IconButton sx={{ backgroundColor: '#FFFFFF', width: 150, height: 150 }}>
                            <AddIcon sx={{ fontSize: 80, color: '#000000' }} />
                        </IconButton>
                        {/* </Box> */}
                    </Box>


                    <Typography variant="h6" sx={{ mb: 1 }}>Product Details</Typography>

                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
                        {/* Left Side */}
                        <Box sx={{ width: '48%' }}>
                            <TextField label="Product Name" variant="filled" fullWidth sx={{ mb: 2, backgroundColor: '#FFFFFF' }} />
                            <TextField label="Enter Price" variant="filled" fullWidth sx={{ mb: 2, backgroundColor: '#FFFFFF' }} />
                            <TextField label="Enter Brand" variant="filled" fullWidth sx={{ mb: 2, backgroundColor: '#FFFFFF' }} />
                            <TextField label="Product Description" variant="filled" multiline rows={4} fullWidth sx={{ backgroundColor: '#FFFFFF' }} />
                        </Box>

                        {/* Right Side */}
                        <Box sx={{ width: '48%' }}>
                            <TextField label="Enter Country" variant="filled" fullWidth sx={{ mb: 2, backgroundColor: '#FFFFFF' }} />
                            <TextField label="Enter Size (Separated with Comma)" variant="filled" fullWidth sx={{ mb: 2, backgroundColor: '#FFFFFF' }} />
                            <TextField label="Enter Color Name (Separated with Comma)" variant="filled" fullWidth sx={{ mb: 2, backgroundColor: '#FFFFFF' }} />
                            <TextField label="Enter SKU" variant="filled" fullWidth sx={{ backgroundColor: '#FFFFFF' }} />
                        </Box>
                    </Box>

                    <Button
                        fullWidth
                        sx={{
                            background: 'linear-gradient(90deg, #00563A 0%, #00AC76 100%)',
                            color: '#fff',
                            textTransform: 'none',
                            padding: 1,
                            borderRadius: 2,
                        }}
                        onClick={handleClose}
                    >
                        Add Product
                    </Button>
                </Box>
            </Modal>
        </Box>
    ) : (
        <Box sx={{ height: '80vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', color: '#FFFFFF' }}>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4, width: '100%', padding: 2 }}>
                <Typography variant="h4" sx={{ fontSize: 30 }}>My Inventory</Typography>

                <Button
                    onClick={handleOpen}
                    sx={{
                        background: 'linear-gradient(90deg, #00563A 0%, #00AC76 100%)',
                        color: '#fff',
                        '&:hover': {
                            background: 'linear-gradient(90deg, #004d2d 0%, #009966 100%)',
                        },
                        textTransform: 'none',
                        padding: 1,
                        borderRadius: 2
                    }}
                >
                    Add Product
                </Button>
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', flex: 1 }}>
                <InventoryIcon sx={{ fontSize: 80, color: '#FFFFFF', mb: 2 }} />
                <Typography variant="body1" sx={{ fontSize: 24, mb: 2, textAlign: 'center' }}>You currently have no Inventory added</Typography>
                <Typography variant="body2" sx={{ fontSize: 18, color: '#FFFFFF', textAlign: 'center' }}>
                    When you've added products, you'll find all the details here
                </Typography>
            </Box>

            {/* Modal for adding product */}
            <Modal open={open} onClose={handleClose} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Box
                    sx={{
                        width: '80%',
                        maxWidth: '800px',
                        bgcolor: '#013927',
                        p: 4,
                        borderRadius: 2,
                        color: '#FFFFFF',
                        overflowY: 'auto',
                    }}
                >
                    <Typography variant="h4" sx={{ mb: 2, fontSize: 30, textAlign: 'center' }}>
                        Add Product
                    </Typography>

                    <Typography variant="h6" sx={{ mb: 1 }}>Add Image</Typography>

                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, gap: 5 }}>
                        <Box sx={{ width: 150, height: 150, backgroundColor: '#FFF', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <ImageIcon sx={{ fontSize: 80, color: '#000000' }} />
                        </Box>
                        {/* <Box sx={{ width: 150, height: 150, backgroundColor: '#FFF', display: 'flex', alignItems: 'center', justifyContent: 'center' }}> */}

                        <IconButton sx={{ backgroundColor: '#FFFFFF', width: 150, height: 150 }}>
                            <AddIcon sx={{ fontSize: 80, color: '#000000' }} />
                        </IconButton>
                        {/* </Box> */}
                    </Box>


                    <Typography variant="h6" sx={{ mb: 1 }}>Product Details</Typography>

                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
                        {/* Left Side */}
                        <Box sx={{ width: '48%' }}>
                            <TextField label="Product Name" variant="filled" fullWidth sx={{ mb: 2, backgroundColor: '#FFFFFF' }} />
                            <TextField label="Enter Price" variant="filled" fullWidth sx={{ mb: 2, backgroundColor: '#FFFFFF' }} />
                            <TextField label="Enter Brand" variant="filled" fullWidth sx={{ mb: 2, backgroundColor: '#FFFFFF' }} />
                            <TextField label="Product Description" variant="filled" multiline rows={4} fullWidth sx={{ backgroundColor: '#FFFFFF' }} />
                        </Box>

                        {/* Right Side */}
                        <Box sx={{ width: '48%' }}>
                            <TextField label="Enter Country" variant="filled" fullWidth sx={{ mb: 2, backgroundColor: '#FFFFFF' }} />
                            <TextField label="Enter Size (Separated with Comma)" variant="filled" fullWidth sx={{ mb: 2, backgroundColor: '#FFFFFF' }} />
                            <TextField label="Enter Color Name (Separated with Comma)" variant="filled" fullWidth sx={{ mb: 2, backgroundColor: '#FFFFFF' }} />
                            <TextField label="Enter SKU" variant="filled" fullWidth sx={{ backgroundColor: '#FFFFFF' }} />
                        </Box>
                    </Box>

                    <Button
                        fullWidth
                        sx={{
                            background: 'linear-gradient(90deg, #00563A 0%, #00AC76 100%)',
                            color: '#fff',
                            textTransform: 'none',
                            padding: 1,
                            borderRadius: 2,
                        }}
                        onClick={handleClose}
                    >
                        Add Product
                    </Button>
                </Box>
            </Modal>
        </Box>
    );
}

export default MyInventory;
