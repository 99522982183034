import React from 'react';
import { Box, Button, Typography, Grid } from '@mui/material';

function MyInformation() {
    return (
        <Box sx={{ padding: 2, backgroundColor: '#013927', height: '80vh', color: '#FFFFFF' }}>
            <Box display="flex" justifyContent="space-between" mb={2}>
                <Typography variant="h4" sx={{ fontSize: 30 }}>My Informations</Typography>
                <Button
                    sx={{
                        background: 'linear-gradient(90deg, #00563A 0%, #00AC76 100%)',
                        color: '#fff',
                        '&:hover': {
                            background: 'linear-gradient(90deg, #004d2d 0%, #009966 100%)',
                        },
                        textTransform: 'none',
                        padding: 1,
                        borderRadius: 2
                    }}
                >
                    Edit Profile
                </Button>
            </Box>

            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <Box sx={{ padding: 2, borderRadius: 2,  }}>
                        <Typography variant="body1" sx={{ mb: 1, fontWeight: 'bold' }}>Full Name</Typography>
                        <Typography variant="body2" sx={{ mb: 2 }}>John Doe</Typography>
                        
                        <Typography variant="body1" sx={{ mb: 1, fontWeight: 'bold' }}>Email Address</Typography>
                        <Typography variant="body2" sx={{ mb: 2 }}>john.doe@example.com</Typography>
                        
                        <Typography variant="body1" sx={{ mb: 1, fontWeight: 'bold' }}>Type of Business</Typography>
                        <Typography variant="body2" sx={{ mb: 2 }}>Retail</Typography>
                        
                        <Typography variant="body1" sx={{ mb: 1, fontWeight: 'bold' }}>Phone Number</Typography>
                        <Typography variant="body2" sx={{ mb: 2 }}>+1234567890</Typography>
                        
                        <Typography variant="body1" sx={{ mb: 1, fontWeight: 'bold' }}>Position of Main Contact</Typography>
                        <Typography variant="body2" sx={{ mb: 2 }}>Manager</Typography>
                        
                        <Typography variant="body1" sx={{ mb: 1, fontWeight: 'bold' }}>Activity Information</Typography>
                        <Typography variant="body2" sx={{ mb: 2 }}>Active</Typography>
                        
                        <Typography variant="body1" sx={{ mb: 1, fontWeight: 'bold' }}>Password</Typography>
                        <Typography variant="body2">******</Typography>
                    </Box>
                </Grid>

                <Grid item xs={12} md={6}>
                    <Box sx={{ padding: 2, borderRadius: 2,  }}>
                        <Typography variant="body1" sx={{ mb: 1, fontWeight: 'bold' }}>Tax Identification Number</Typography>
                        <Typography variant="body2" sx={{ mb: 2 }}>123-45-6789</Typography>
                        
                        <Typography variant="body1" sx={{ mb: 1, fontWeight: 'bold' }}>Name of Primary Contact</Typography>
                        <Typography variant="body2" sx={{ mb: 2 }}>Jane Doe</Typography>
                        
                        <Typography variant="body1" sx={{ mb: 1, fontWeight: 'bold' }}>Company Description</Typography>
                        <Typography variant="body2" sx={{ mb: 2 }}>Leading provider of retail products.</Typography>
                        
                        <Typography variant="body1" sx={{ mb: 1, fontWeight: 'bold' }}>General Conditions</Typography>
                        <Typography variant="body2" sx={{ mb: 2 }}>Standard terms apply.</Typography>
                        
                        <Typography variant="body1" sx={{ mb: 1, fontWeight: 'bold' }}>Confirm Password</Typography>
                        <Typography variant="body2">******</Typography>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}

export default MyInformation;
