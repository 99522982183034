// pages/ProtectedPage.js
import React from 'react';
import { Container, Typography, Paper } from '@mui/material';

const ProtectedPage = () => {
  return (
    <Container component="main" maxWidth="xs">
      <Paper elevation={3} sx={{ padding: 2 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Protected Page
        </Typography>
        <Typography variant="body1">
          This is a protected page. Only authenticated users can see this.
        </Typography>
      </Paper>
    </Container>
  );
};

export default ProtectedPage;
