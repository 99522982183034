import React, { useEffect, useState } from 'react';

import Slider from 'react-slick';
import { Box, Typography, Button } from '@mui/material';
import Header from './components/Header';
import Footer from './components/Footer';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import image from "../assets/iim.png"
// Sample box data
const sliderContent = [
    {
        image: 'https://firebasestorage.googleapis.com/v0/b/webcommerce-f85da.appspot.com/o/Group%201000002113.png?alt=media&token=8b3c039d-5182-45f7-a71a-0d8f401b7297',
        title: 'Prada',
        subtitle: 'Runway',
        description: 'Single-breasted pinstripe wool vest',
    },
    {
        image: 'https://firebasestorage.googleapis.com/v0/b/webcommerce-f85da.appspot.com/o/Group%201000002114.png?alt=media&token=bec0ad29-88ea-4427-94e1-97a53a2e1267',
        title: 'Versace',
        subtitle: 'Runway',
        description: 'Medusa-motif metal bracelet',
    },
    {
        image: 'https://firebasestorage.googleapis.com/v0/b/webcommerce-f85da.appspot.com/o/Group%201000002115.png?alt=media&token=346864b7-bd30-40d6-89ad-e7ad58e44a4e',
        title: 'Erdem',
        subtitle: 'Runway',
        description: 'floral-print pencil skirt',
    },
    {
        image: 'https://firebasestorage.googleapis.com/v0/b/webcommerce-f85da.appspot.com/o/Group%201000002116.png?alt=media&token=9a2d4f4c-f784-4fdf-b593-b1db3b3722c3',
        title: 'Hermès Pre-Owned',
        subtitle: 'Runway',
        description: '2012 Birkin 30 handbag',
    },
];

const Trending = () => {
    // Slider settings
    const calculateTimeLeft = () => {
        const endDate = new Date('2024-09-30T00:00:00'); // Set your end date here
        const now = new Date();
        const difference = endDate - now;

        if (difference <= 0) return { days: 0, hours: 0, minutes: 0, seconds: 0 };

        const seconds = Math.floor((difference / 1000) % 60);
        const minutes = Math.floor((difference / 1000 / 60) % 60);
        const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));

        return { days, hours, minutes, seconds };
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft);

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearInterval(timer);
    }, []);



    const sliderSettings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4, // Default number of slides to show
        slidesToScroll: 1,
        arrows: false, // Disable arrows
        responsive: [
            {
                breakpoint: 1024, // Tablet breakpoint
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 600, // Mobile breakpoint
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <div
            style={{
                backgroundColor: "#002016",
                width: "100%",
                padding: 0,
            }}
        >
            <Header />

            {/* New Arrival Section */}
            <Box
                sx={{

                    display: 'flex',
                    marginLeft:
                    {
                        xs: '20px',
                        sm: '23px',
                        md: '23px',
                        lg: '50px',


                    }

                    ,
                    marginRight: {
                        xs: '20px',
                        sm: '23px',
                        md: '23px',
                        lg: '50px',


                    }
                    ,
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    backgroundColor: '#002016',
                    padding: {
                        xs: '1rem',
                        sm: '1.5rem',
                        md: '2rem',
                        lg: '2rem',
                    },
                    color: 'white',
                }}
            >
                <Typography
                    variant="h4"
                    sx={{
                        fontSize: {
                            xs: '20px',
                            sm: '20px',
                            md: '20px',
                            lg: '20px',
                        },
                        fontWeight: 'bold',
                                            fontFamily: 'Volkhov'
                    }}
                >
                    New Arrival
                </Typography>
                <Button
                    variant="contained"
                    sx={{
                        marginTop: "20px",
                        width: {
                            xs: '90px',
                            sm: '90px',
                            md: '167px',
                            lg: "167px"
                        },
                        height: '90%',
                        background: 'linear-gradient(to right, #00563A, #00AC76)',
                        color: '#fff',
                        fontSize: {
                            xs: '13px',
                            sm: '13px',
                            md: '19px',
                            lg: "19px"
                        },
                        borderRadius: '10px',
                        textTransform: 'none',
                    }}
                >
                    Buy Now
                </Button>
            </Box>

            {/* Slider */}
            <Box
                sx={{
                    margin: {
                        xs: '1rem',
                        sm: '2rem',
                        md: '1rem',
                        lg: '1rem',
                    },
                }}
            >
                <Slider {...sliderSettings}>
                    {sliderContent.map((content, index) => (
                        <Box key={index} sx={{ padding: '0 10px' }}>
                            <Box
                                sx={{
                                    boxShadow: '0px 0px 0px rgba(0, 0, 0, 0.5)', // Black shadow effect

                                    backgroundColor: '#013927',
                                    height: {
                                        xs: '400px',  // Mobile height
                                        sm: '400px',  // Small screen height
                                        md: '400px',  // Medium screen height
                                        lg: '400px',  // Large screen height
                                    },
                                    width: {
                                        xs: '280px',  // Mobile width
                                        sm: '300px',  // Small screen width
                                        md: '337px',  // Medium screen width
                                        lg: '337px',  // Large screen width
                                    },
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    padding: '15px',
                                    borderRadius: '8px',
                                    marginLeft: 'auto',  // Ensures left and right margins are equal
                                    marginRight: 'auto',
                                }}
                            >
                                {/* Centered Image */}
                                <img
                                    src={content.image}
                                    alt={`Slide ${index + 1}`}
                                    style={{
                                        width: '100%',
                                        height: 'auto',
                                        maxHeight: '311px',  // Image max height to keep proportions
                                        objectFit: 'cover',
                                        borderRadius: '8px',
                                    }}
                                />

                                {/* Text content */}
                                <Box sx={{ width: '100%', textAlign: 'left', marginTop: '15px' }}>
                                    <Typography variant="subtitle2" sx={{ color: '#A9A9A9', fontWeight: 'normal', fontSize: "14px" }}>
                                        {content.subtitle}
                                    </Typography>
                                    <Typography variant="h6" sx={{ color: '#fff', fontWeight: 'bold', fontSize: "18px" }}>
                                        {content.title}
                                    </Typography>
                                    <Typography variant="body2" sx={{ color: '#C3C3C3', fontSize: "14px" }}>
                                        {content.description}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    ))}
                </Slider>
            </Box>
            <Box
                sx={{
                    height: '782px',
                    backgroundColor: '#013927',
                    marginTop: "70px",
                    marginBottom: "70px",
                    display: 'grid',
                    gridTemplateColumns: {
                        xs: '1fr', // Mobile: 1 column
                        md: '1fr 1fr', // Medium and up: 2 columns
                    },
                    gap: '30px', // Space between the columns
                    padding: {
                        xs: '0 20px', // Mobile horizontal padding
                        md: '0 135px', // Medium and up horizontal padding
                    },
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                {/* Left box with text and button */}
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: {
                            xs: 'center', // Center content on mobile
                            md: 'flex-start', // Start content on medium and up
                        },
                        color: '#fff',
                        textAlign: {
                            xs: 'center', // Center text on mobile
                            md: 'left', // Left-align text on medium and up
                        },
                    }}
                >
                    <Typography variant="h4" sx={{
                        marginBottom: '30px',
                        fontSize: {
                            xs: "24px", // Font size for extra small screens
                            sm: "24px", // Font size for small screens (tablets)
                            md: "32px", // Font size for medium screens
                            lg: "46px",
                        },
                        fontWeight: "bold",
                                                                    fontFamily: 'Volkhov'
                    }}>
                        Deals Of The Month
                    </Typography>
                    <Typography variant="body1" sx={{
                        width: {
                            xs: "100%", // Width for extra small screens
                            sm: "100%", // Width for small screens (tablets)
                            md: "80%", // Width for medium screens
                            lg: "50%",
                        },
                        color: "#C1C1C1"
                    }} >
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Scelerisque duis ultrices sollicitudin aliquam sem. Scelerisque duis ultrices sollicitudin
                    </Typography>

                    {/* Wrap button and additional typography in a separate Box */}
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: {
                                xs: 'center', // Center button on mobile
                                md: 'flex-start', // Start button on medium and up
                            },
                            marginTop: '20px',
                        }}
                    >
                        <Button
                            variant="contained"
                            sx={{
                                width: {
                                    xs: '90px',
                                    sm: '90px',
                                    md: '167px',
                                    lg: "167px"
                                },
                                height: '90%',
                                background: 'linear-gradient(to right, #00563A, #00AC76)',
                                color: '#fff',
                                fontSize: {
                                    xs: '13px',
                                    sm: '13px',
                                    md: '19px',
                                    lg: "19px"
                                },
                                borderRadius: '10px',
                                textTransform: 'none',
                            }}
                        >
                            Buy Now
                        </Button>
                        <Typography variant="h4" sx={{
                            marginTop: '30px',
                                                                        fontFamily: 'Volkhov',
                            fontSize: {
                                xs: "14px", // Font size for extra small screens
                                sm: "14px", // Font size for small screens (tablets)
                                md: "22px", // Font size for medium screens
                                lg: "28px",
                            },
                            fontWeight: "bold"
                        }}>
                            Hurry, Before It’s Too Late!
                        </Typography>

                        {/* Countdown Timer */}
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center', // Center countdown on mobile
                                flexWrap: 'wrap',
                                marginTop: '40px',
                            }}
                        >
                            {['days', 'hours', 'minutes', 'seconds'].map((unit) => (
                                <Box
                                    key={unit}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        margin: '0 10px',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            height: '76px',
                                            width: '76px',
                                            backgroundColor: '#00A773',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            borderRadius: '8px',
                                        }}
                                    >
                                        <Typography variant="h6" sx={{
                                            color: '#fff', fontWeight: 'bold',

                                            fontSize: {
                                                xs: "17px", // Font size for extra small screens
                                                sm: "17px", // Font size for small screens (tablets)
                                                md: "28px", // Font size for medium screens
                                                lg: "32px",
                                            },
                                            fontFamily: 'DigitalNumber' // Apply custom font

 // Apply custom font


                                        }}>
                                            {timeLeft[unit]} {/* Display countdown value */}
                                        </Typography>
                                    </Box>
                                    <Typography variant="caption" sx={{
                                        // fontFamily: 'DigitalNumber' // Apply custom font
                                        fontSize: {
                                            xs: "14px", // Font size for extra small screens
                                            sm: "14px", // Font size for small screens (tablets)
                                            md: "18px", // Font size for medium screens
                                            lg: "20px",
                                        },

                                        color: '#fff', marginTop: '5px'
                                    }}>
                                        {unit.charAt(0).toUpperCase() + unit.slice(1)} {/* Capitalize the first letter */}
                                    </Typography>
                                </Box>
                            ))}
                        </Box>
                    </Box>
                </Box>

                {/* Right box with background image */}
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: {
                            xs: 'center', // Center image on mobile
                            md: 'flex-end', // End image on medium and up
                        },
                        alignItems: 'center',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            height: {
                                xs: '300px', // Mobile height
                                sm: '400px', // Small screens height
                                md: '500px', // Medium screens height
                                lg: '637px', // Large screens height
                            },
                            width: {
                                xs: '300px', // Mobile width
                                sm: '400px', // Small screens width
                                md: '500px', // Medium screens width
                                lg: '637px', // Large screens width
                            },
                            backgroundImage: `url(${image})`, // Use the local image
                            backgroundSize: 'cover', // Cover the entire box
                            backgroundPosition: 'center', // Center the image
                            boxShadow: '0px 0px 0px rgba(0, 0, 0, 0.5)', // Black shadow effect
                        }}
                    />
                </Box>
            </Box>


            <Typography
                    variant="h4"
                    sx={{
                        fontSize: {
                            xs: '20px',
                            sm: '20px',
                            md: '28px',
                            lg: '32px',
                        },
                        fontWeight: 'normal',
                    textAlign:"center",
                    fontFamily: 'Volkhov' ,
                    color:"#fff"           ,
                    marginBottom:"30px"        
                    }}
                >
                   Trending this week
                </Typography>

                <Box
                sx={{
                    margin: {
                        xs: '1rem',
                        sm: '2rem',
                        md: '1rem',
                        lg: '1rem',
                    },
                }}
            >
                <Slider {...sliderSettings}>
                    {sliderContent.map((content, index) => (
                        <Box key={index} sx={{ padding: '0 10px' }}>
                            <Box
                                sx={{
                                    boxShadow: '0px 0px 0px rgba(0, 0, 0, 0.5)', // Black shadow effect

                                    backgroundColor: '#013927',
                                    height: {
                                        xs: '400px',  // Mobile height
                                        sm: '400px',  // Small screen height
                                        md: '400px',  // Medium screen height
                                        lg: '400px',  // Large screen height
                                    },
                                    width: {
                                        xs: '280px',  // Mobile width
                                        sm: '300px',  // Small screen width
                                        md: '337px',  // Medium screen width
                                        lg: '337px',  // Large screen width
                                    },
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    padding: '15px',
                                    borderRadius: '8px',
                                    marginLeft: 'auto',  // Ensures left and right margins are equal
                                    marginRight: 'auto',
                                }}
                            >
                                {/* Centered Image */}
                                <img
                                    src={content.image}
                                    alt={`Slide ${index + 1}`}
                                    style={{
                                        width: '100%',
                                        height: 'auto',
                                        maxHeight: '311px',  // Image max height to keep proportions
                                        objectFit: 'cover',
                                        borderRadius: '8px',
                                    }}
                                />

                                {/* Text content */}
                                <Box sx={{ width: '100%', textAlign: 'left', marginTop: '15px' }}>
                                    <Typography variant="subtitle2" sx={{ color: '#A9A9A9', fontWeight: 'normal', fontSize: "14px" }}>
                                        {content.subtitle}
                                    </Typography>
                                    <Typography variant="h6" sx={{ color: '#fff', fontWeight: 'bold', fontSize: "18px" }}>
                                        {content.title}
                                    </Typography>
                                    <Typography variant="body2" sx={{ color: '#C3C3C3', fontSize: "14px" }}>
                                        {content.description}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    ))}
                </Slider>
            </Box>

            <Box
                sx={{

                    display: 'flex',
                    marginLeft:
                    {
                        xs: '20px',
                        sm: '23px',
                        md: '23px',
                        lg: '70px',


                    }

                    ,
                    marginRight: {
                        xs: '20px',
                        sm: '23px',
                        md: '23px',
                        lg: '50px',


                    }
                    ,
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    backgroundColor: '#002016',
                    padding: {
                        xs: '1rem',
                        sm: '1.5rem',
                        md: '2rem',
                        lg: '2rem',
                    },
                    color: 'white',
                }}
            >
                <Typography
                    variant="h4"
                    sx={{
                        fontSize: {
                            xs: '20px',
                            sm: '20px',
                            md: '20px',
                            lg: '20px',
                        },
                        fontWeight: 'bold',
                                            fontFamily: 'Volkhov'
                    }}
                >
                    Most Selling 
                </Typography>
                <Button
                    variant="contained"
                    sx={{
                        marginTop: "20px",
                        width: {
                            xs: '90px',
                            sm: '90px',
                            md: '167px',
                            lg: "167px"
                        },
                        height: '90%',
                        background: 'linear-gradient(to right, #00563A, #00AC76)',
                        color: '#fff',
                        fontSize: {
                            xs: '13px',
                            sm: '13px',
                            md: '19px',
                            lg: "19px"
                        },
                        borderRadius: '10px',
                        textTransform: 'none',
                    }}
                >
                    Buy Now
                </Button>
            </Box>

            {/* Slider */}
            <Box
                sx={{
                    margin: {
                        xs: '1rem',
                        sm: '2rem',
                        md: '1rem',
                        lg: '1rem',
                    },
                }}
            >
                <Slider {...sliderSettings}>
                    {sliderContent.map((content, index) => (
                        <Box key={index} sx={{ padding: '0 10px' }}>
                            <Box
                                sx={{
                                    boxShadow: '0px 0px 0px rgba(0, 0, 0, 0.5)', // Black shadow effect

                                    backgroundColor: '#013927',
                                    height: {
                                        xs: '400px',  // Mobile height
                                        sm: '400px',  // Small screen height
                                        md: '400px',  // Medium screen height
                                        lg: '400px',  // Large screen height
                                    },
                                    width: {
                                        xs: '280px',  // Mobile width
                                        sm: '300px',  // Small screen width
                                        md: '337px',  // Medium screen width
                                        lg: '337px',  // Large screen width
                                    },
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    padding: '15px',
                                    borderRadius: '8px',
                                    marginLeft: 'auto',  // Ensures left and right margins are equal
                                    marginRight: 'auto',
                                }}
                            >
                                {/* Centered Image */}
                                <img
                                    src={content.image}
                                    alt={`Slide ${index + 1}`}
                                    style={{
                                        width: '100%',
                                        height: 'auto',
                                        maxHeight: '311px',  // Image max height to keep proportions
                                        objectFit: 'cover',
                                        borderRadius: '8px',
                                    }}
                                />

                                {/* Text content */}
                                <Box sx={{ width: '100%', textAlign: 'left', marginTop: '15px' }}>
                                    <Typography variant="subtitle2" sx={{ color: '#A9A9A9', fontWeight: 'normal', fontSize: "14px" }}>
                                        {content.subtitle}
                                    </Typography>
                                    <Typography variant="h6" sx={{ color: '#fff', fontWeight: 'bold', fontSize: "18px" }}>
                                        {content.title}
                                    </Typography>
                                    <Typography variant="body2" sx={{ color: '#C3C3C3', fontSize: "14px" }}>
                                        {content.description}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    ))}
                </Slider>
            </Box>

            <Footer />
        </div>
    );
};

export default Trending;
