// components/Body.js
import React from "react";
import { Button, Container, Typography, Box, Grid, TextField } from "@mui/material";
import Laptop from "../../assets/Laptop.png"; // Import your background image here
import Bottom from "../../assets/Bottom.png"; // Import your background image here
import Footer from "./Footer";

const Body = () => {
  const placeholderImages = [
    {
      image:
        "https://firebasestorage.googleapis.com/v0/b/webcommerce-f85da.appspot.com/o/icon%20(1).png?alt=media&token=332a4aec-8386-4c94-b493-93ec1e6f46a1", // Placeholder image URL
      text: "High Quality",
      subText: "crafted from top materials",
    },
    {
      image:
        "https://firebasestorage.googleapis.com/v0/b/webcommerce-f85da.appspot.com/o/icon.png?alt=media&token=72adc7ca-9072-4066-9c67-dfc021dccf8c", // Placeholder image URL
      text: "Warrany Protection",
      subText: "Life time",
    },
    {
      image:
        "https://firebasestorage.googleapis.com/v0/b/webcommerce-f85da.appspot.com/o/Vector%402x.png?alt=media&token=48a2986f-b9d7-4ae2-abb3-728b046c40c9", // Placeholder image URL
      text: "Free Shipping",
      subText: "Order over 150 $",
    },
    {
      image:
        "https://firebasestorage.googleapis.com/v0/b/webcommerce-f85da.appspot.com/o/2891214031638194523%201%402x.png?alt=media&token=d3f6eac7-f2ca-4969-9bd2-d602d4a0819e", // Placeholder image URL
      text: "Need Help?",
      subText: "Dedicated support",
    },
  ];
  return (
    <>
      <div
        style={{
          // marginTop: 4,
          backgroundColor: "#002016",
          width: "100%",
          // position: 'relative' // Set position to relative for absolute positioning of inner Box

          padding: 0,
          // overflow: 'hidden'   // Ensures that any overflow content is hidden
        }}
        maxWidth={false}
      >
        <Box sx={{ textAlign: "center" }}>
          <Typography
            variant="h4"
            component="h2"
            gutterBottom
            sx={{
              color: "white",
              fontWeight: "bold",
              paddingTop: "40px",
              paddingBottom: "40px",
              fontSize: {
                xs: "20px", // Font size for extra small screens
                sm: "24px", // Font size for small screens (tablets)
                md: "30px", // Font size for medium screens
                lg: "37px", // Font size for large screens
              },
            }}
          >
            Insights Hub
          </Typography>
        </Box>
        <img
          src={Laptop}
          alt="Background"
          style={{
            width: "100%",
            marginTop: "-100px",
            height: {
              xs: "400px", // Height for extra small screens
              sm: "500px", // Height for small screens (tablets)
              md: "600px", // Height for medium screens
              lg: "110vh", // Height for large screens (desktops) as full viewport height
            },
            objectFit: "contain", // Ensures the image covers the container while preserving aspect ratio
            // display: 'block',  // Removes any extra space below the image
            margin: 0, // Removes any margin around the image
            padding: 0, // Removes any padding around the image
          }}
        ></img>
        <img
          src={Bottom}
          alt="Bottom"
          style={{
            width: "100%",
            marginTop: "-100px",
            height: {
              xs: "400px", // Height for extra small screens
              sm: "500px", // Height for small screens (tablets)
              md: "600px", // Height for medium screens
              lg: "110vh", // Height for large screens (desktops) as full viewport height
            },
            objectFit: "contain", // Ensures the image covers the container while preserving aspect ratio
            // display: 'block',  // Removes any extra space below the image
            margin: 0, // Removes any margin around the image
            padding: 0, // Removes any padding around the image
          }}
        ></img>
<Footer/>

      </div>
    </>
  );
};

export default Body;
