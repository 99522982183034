// pages/HomePage.js
import React from 'react';
import HeroSection from './components/HeroSection';
import Body from './components/Body';
import Header from './components/Header';

const HomePage = () => {
  return (
    <div>
      <Header />
      <HeroSection />
      <Body />
    </div>
  );
};

export default HomePage;
