import React, { useState } from 'react';
import { Box, Button, Typography, TextField, MenuItem, Modal, FormControl, InputLabel, Select } from '@mui/material';

function MyAddress() {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '80vh', padding: 2 }}>
            <Box display="flex" justifyContent="space-between" mb={2}>
                <Typography variant="h6" sx={{ fontSize: 30 }}>My Address Management</Typography>
                <Button
                    onClick={handleOpen}
                    sx={{
                        background: 'linear-gradient(90deg, #00563A 0%, #00AC76 100%)',
                        color: '#fff',
                        '&:hover': {
                            background: 'linear-gradient(90deg, #004d2d 0%, #009966 100%)',
                        },
                        textTransform: 'none',
                        padding: 1,
                        borderRadius: 2
                    }}
                >
                    Add New Address
                </Button>
            </Box>

            <Box sx={{ flex: 1, backgroundColor: '#013927', padding: 2, borderRadius: 5 }}>
                <Box
                    display="flex"
                    flexWrap="wrap"
                    sx={{ backgroundColor: 'transparent', padding: 2, width: 'fit-content', border: '1px solid #FFFFFF', borderRadius: 2 }}
                    mb={2}
                >
                    <Box
                        sx={{
                            flexGrow: 1,
                            minWidth: '300px',
                            height: '100px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'flex-start',
                            gap: '5px'
                        }}
                    >
                        <Typography variant="body1">John William</Typography>
                        <Typography variant="body2">john@example.com</Typography>
                        <Typography variant="body2">123 Main Street</Typography>
                    </Box>

                    <Box>
                        <Button sx={{ background: "transparent", color: "#fff" }}>Edit</Button>
                    </Box>
                </Box>
            </Box>

            {/* Modal */}
            <Modal
                open={open}
                onClose={handleClose}
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
                <Box
                    sx={{
                        backgroundColor: 'white',
                        padding: 3,
                        borderRadius: 2,
                        width: '400px',
                        maxWidth: '90%',
                    }}
                >
                    <Typography variant="h6" gutterBottom>Add Shipping Address</Typography>

                    <Typography variant="body1" sx={{ mb: 1 }}>Contact</Typography>
                    <TextField 
                        label="Email Address" 
                        variant="outlined" 
                        fullWidth 
                        sx={{ mb: 2 }} 
                    />

                    <Typography variant="body1" sx={{ mb: 1 }}>Delivery</Typography>
                    <FormControl fullWidth sx={{ mb: 2 }}>
                        <InputLabel>Country</InputLabel>
                        <Select defaultValue="" label="Country">
                            <MenuItem value="USA">USA</MenuItem>
                            <MenuItem value="Canada">Canada</MenuItem>
                            <MenuItem value="Mexico">Mexico</MenuItem>
                        </Select>
                    </FormControl>

                    <Box display="flex" justifyContent="space-between" mb={2}>
                        <TextField label="First Name" variant="outlined" fullWidth sx={{ mr: 1 }} />
                        <TextField label="Last Name" variant="outlined" fullWidth sx={{ ml: 1 }} />
                    </Box>

                    <TextField label="Address" variant="outlined" fullWidth sx={{ mb: 2 }} />

                    <Box display="flex" justifyContent="space-between">
                        <TextField label="City" variant="outlined" fullWidth sx={{ mr: 1 }} />
                        <TextField label="Postal Code" variant="outlined" fullWidth sx={{ ml: 1 }} />
                    </Box>

                    <Button
                        onClick={handleClose}
                        sx={{
                            background: 'linear-gradient(90deg, #00563A 0%, #00AC76 100%)',
                            color: '#fff',
                            '&:hover': {
                                background: 'linear-gradient(90deg, #004d2d 0%, #009966 100%)',
                            },
                            textTransform: 'none',
                            padding: 1,
                            borderRadius: 2,
                            mt: 2
                        }}
                    >
                        Submit
                    </Button>
                </Box>
            </Modal>
        </Box>
    );
}

export default MyAddress;
