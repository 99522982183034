// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import HomePage from './pages/HomePage'; // Example page
import LoginPage from './pages/LoginPage'; // Example page
import ProtectedPage from './pages/ProtectedPage'; // Page to protect
import MyAccount from './pages/myaccount/myaccount';
import Trending from './pages/Trending';

// Mock authentication function
const isAuthenticated = () => {
  // Replace this with actual authentication logic
  return localStorage.getItem('auth') === 'true';
};

// PrivateRoute component to protect routes
const PrivateRoute = ({ element: Element }) => {
  return isAuthenticated() ? Element : <Navigate to="/login" />;
};

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />

        <Route path="/login" element={<LoginPage />} />
        <Route path="/protected" element={<PrivateRoute element={<ProtectedPage />} />} />
        <Route path="/account" element={<MyAccount />} />
        <Route path="/trending" element={<Trending />} />
      </Routes>
    </Router>
  );
};

export default App;
