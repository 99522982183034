import React, { useState } from 'react';
import { Box, Typography, Tabs, Tab, Card, CardMedia, CardContent } from '@mui/material';

export default function MyOrders() {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const renderProductCard = () => (
    <Card sx={{ backgroundColor: '#013927', color: 'white', mb: 2,flex:'block',padding:2 }}>
      <Typography variant="h6">Order #4985487587</Typography>
      <Typography variant="body2">Proceed Date: 2023-09-07</Typography>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {/* Image on the left */}
        <CardMedia
          component="img"
          sx={{ width: 150 ,borderRadius:2}}
          image="https://via.placeholder.com/150"
          alt="Product Image"
        />
        {/* Content on the right */}
        <CardContent sx={{ flex: '1 0 auto' }}>
      
          <Typography variant="h5" sx={{ mt: 1 }}>Product Title</Typography>
          <Typography variant="body2">Short description of the product goes here.</Typography>
          <Typography variant="subtitle1" sx={{ mt: 1 }}>Amount: $100</Typography>
          <Typography variant="subtitle1">Quantity: 2</Typography>
        </CardContent>
      </Box>
    </Card>
  );

  return (
    <Box sx={{ padding: 3 }}>
      {/* User's Name */}
      <Typography variant="h4" gutterBottom>My Order</Typography>

      {/* Tabs */}
      <Tabs value={selectedTab} onChange={handleTabChange} textColor="inherit" indicatorColor="primary">
        <Tab label="Orders" />
        <Tab label="Cancellation" />
        <Tab label="Returns" />
      </Tabs>

      {/* Display content based on the selected tab */}
      <Box sx={{ mt: 3 }}>
        {selectedTab === 0 && (
          <Box>
            {/* Example of product card */}
            {renderProductCard()}
            {renderProductCard()}
          </Box>
        )}
        {selectedTab === 1 && <Typography>No cancellations found.</Typography>}
        {selectedTab === 2 && <Typography>No returns found.</Typography>}
      </Box>
    </Box>
  );
}
