import React, { useState, memo } from 'react';
import { Typography, Box, List, ListItem, ListItemText, Divider, ListItemIcon, Menu, MenuItem, IconButton, useMediaQuery } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import PaymentIcon from '@mui/icons-material/Payment';
import InventoryIcon from '@mui/icons-material/Inventory';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import Myorder from './pages/myorder';
import Myaddress from './pages/myaddress';
import MyInformation from './pages/MyInformation';
import MyPayment from './pages/MyPayment';
import Myinventory from './pages/Myinventory';
import Header from "../components/Header"
import Footer from '../components/Footer';
const MenuItemComponent = memo(({ text, icon, selected, onClick }) => (
  <ListItem
    button
    onClick={onClick}
    sx={{
      backgroundColor: selected ? 'white' : 'transparent',
      color: selected ? 'black' : 'white',
      mb: 2, // Add margin between tabs
      borderRadius: '4px', // Optional: give a rounded corner effect
      '&:hover': {
        backgroundColor: selected ? 'white' : '#333',
      }
    }}
  >
    <ListItemIcon sx={{ color: selected ? 'black' : 'white' }}>
      {icon}
    </ListItemIcon>
    <ListItemText primary={text} />
  </ListItem>
));

export default function MyAccount() {
  const [selectedScreen, setSelectedScreen] = useState('My Orders');
  const [anchorEl, setAnchorEl] = useState(null);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  const menuItems = [
    { text: 'My Orders', icon: <ShoppingCartIcon /> },
    { text: 'My Address', icon: <HomeIcon /> },
    { text: 'My Information', icon: <InfoIcon /> },
    { text: 'My Payments', icon: <PaymentIcon /> },
    { text: 'My Inventory', icon: <InventoryIcon /> },
    { text: 'My Sales', icon: <TrendingUpIcon /> }
  ];

  const renderContent = () => {
    switch (selectedScreen) {
      case 'My Orders': return <Myorder />;
      case 'My Address': return <Myaddress />;
      case 'My Information': return <MyInformation />;
      case 'My Payments': return <MyPayment />;
      case 'My Inventory': return <Myinventory />;
      case 'My Sales': return <div>My Sales Content</div>;
      default: return <div>My Orders Content</div>;
    }
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (screen) => {
    setSelectedScreen(screen);
    setAnchorEl(null);
  };

  return (
    <>
      {/* <Header /> */}
<Header/>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100vh',
          backgroundColor: '#002016',
          color: 'white',
          padding: { xs: '0 1rem', sm: '0 2rem' }
        }}
      >
        <Box sx={{ padding: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h3" gutterBottom sx={{ color: 'white' }}>
            My Account
          </Typography>

          {isSmallScreen && (
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleMenuClick}
              aria-label="menu"
            >
              <MenuIcon />
            </IconButton>
          )}
          <Divider sx={{ backgroundColor: 'white' }} />
        </Box>

        <Box
          sx={{
            display: 'flex',
            flex: 1,
            height: 'calc(100vh - 70px)', // Adjust based on the height of the Header
          }}
        >
          {/* Menu Side */}
          {!isSmallScreen ? (
            <Box
              sx={{
                width: 250,
                borderRight: '1px solid #ccc',
                padding: 2,
                overflowY: 'auto' // To handle overflow if needed
              }}
            >
              <List>
                {menuItems.map(({ text, icon }) => (
                  <MenuItemComponent
                    key={text}
                    text={text}
                    icon={icon}
                    selected={selectedScreen === text}
                    onClick={() => setSelectedScreen(text)}
                  />
                ))}
              </List>
            </Box>
          ) : (
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
            >
              {menuItems.map(({ text, icon }) => (
                <MenuItem
                  key={text}
                  onClick={() => handleClose(text)}
                >
                  <ListItemIcon sx={{ color: 'black' }}>
                    {icon}
                  </ListItemIcon>
                  <ListItemText primary={text} />
                </MenuItem>
              ))}
            </Menu>
          )}

          {/* Content Side */}
          <Box
            sx={{
              flex: 1,
              padding: 2,
              color: 'white',
              overflowY: 'auto' // To handle overflow if needed
            }}
          >
            {renderContent()}
          </Box>
        </Box>
      </Box>
      <Footer/>
    </>
  );
}
