import React, { useState } from 'react';
import { Box, Button, Typography, TextField, MenuItem, FormControl, InputLabel, Select } from '@mui/material';
import BankIcon from '@mui/icons-material/AccountBalance'; // Replace with your own icon

function MyPayment() {
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <Box sx={{ padding: 2, height: '80vh', color: '#FFFFFF' }}>
            <Typography variant="h4" sx={{ fontSize: 30, mb: 2 }}>My Payment Method</Typography>

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    backgroundColor: '#013927',
                    padding: 3,
                    borderRadius: 2
                }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <BankIcon sx={{ color: '#FFFFFF', mr: 1 }} />
                    <Typography variant="body1">Add Bank Card</Typography>
                </Box>
                <Button
                    onClick={handleOpen}
                    sx={{
                        background: 'linear-gradient(90deg, #00563A 0%, #00AC76 100%)',
                        color: '#fff',
                        '&:hover': {
                            background: 'linear-gradient(90deg, #004d2d 0%, #009966 100%)',
                        },
                        textTransform: 'none',
                        padding: 1,
                        borderRadius: 2
                    }}
                >
                    Add New Card
                </Button>
            </Box>

            {open && (
                <Box
                    sx={{
                        position: 'fixed',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        backgroundColor: '#013927',
                        padding: 3,
                        borderRadius: 2,
                        width: '90%',
                        maxWidth: '500px',
                        boxShadow: 3
                    }}
                >
                    <Typography variant="h4" sx={{ fontSize: 30, mb: 2, color: '#FFFFFF' }}>Payment</Typography>

                    <FormControl fullWidth sx={{ mb: 2 }}>
                        {/* <InputLabel id="payment-type-label" sx={{ color: '#FFFFFF' }}>Payment Type</InputLabel> */}
                        <Select
                            labelId="payment-type-label"
                            defaultValue="credit"
                            sx={{
                                backgroundColor: '#FFFFFF',
                                color: '#000000',
                                '& .MuiOutlinedInput-notchedOutline': { border: 'none' },
                                '& .MuiSelect-select': { padding: '12px 14px' },
                                '&:focus': { outline: 'none' }
                            }}
                        >
                            <MenuItem value="credit">Credit</MenuItem>
                            <MenuItem value="debit">Debit</MenuItem>
                        </Select>
                    </FormControl>

                    <TextField
                        label="Card Number"
                        variant="filled"
                        fullWidth
                        sx={{ mb: 2, backgroundColor: '#FFFFFF', color: '#000000', '& .MuiOutlinedInput-root': { '& fieldset': { borderColor: '#000000' } } }}
                    />

                    <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
                        <TextField
                            label="Expired Date"
                            variant="filled"
                            sx={{ flex: 1, backgroundColor: '#FFFFFF', color: '#000000', '& .MuiOutlinedInput-root': { '& fieldset': { borderColor: '#000000' } } }}
                        />
                        <TextField
                            label="Security Code"
                            variant="filled"
                            sx={{ flex: 1, backgroundColor: '#FFFFFF', color: '#000000', '& .MuiOutlinedInput-root': { '& fieldset': { borderColor: '#000000' } } }}
                        />
                    </Box>

                    <TextField
                        label="Cardholder Name"
                        variant="filled"
                        fullWidth
                        sx={{ mb: 2, backgroundColor: '#FFFFFF', color: '#000000', '& .MuiOutlinedInput-root': { '& fieldset': { borderColor: '#000000' } } }}
                    />

                    <Button
                        variant="contained"
                        sx={{
                            width: '100%',
                            background: 'linear-gradient(90deg, #00563A 0%, #00AC76 100%)',
                            color: '#fff',
                            '&:hover': {
                                background: 'linear-gradient(90deg, #004d2d 0%, #009966 100%)',
                            },
                            textTransform: 'none',
                            padding: 1,
                            borderRadius: 2
                        }}
                        onClick={()=>{
                            setOpen(!open)
                        }}
                    >
                        Payment
                    </Button>
                </Box>
            )}
        </Box>
    );
}

export default MyPayment;
